$paper: #ffffff;

$primaryLight: #e3f2fd;
$primaryMain: #0241FF;
$primaryDark: #1e88e5;
$primary200: #90caf9;
$primary800: #1565c0;

$secondaryLight: #DBEBFF;
$secondaryMain: #4A9AFF;
$secondaryDark: #346CB3;
$secondary200: #B7D7FF;
$secondary800: #346CB3;

$successLight: #b9f6ca;
$success200: #69f0ae;
$successMain: #00e676;
$successDark: #00c853;

$errorLight: #ef9a9a;
$errorMain: #f44336;
$errorDark: #c62828;

$orangeLight: #fbe9e7;
$orangeMain: #ffab91;
$orangeDark: #d84315;

$warningLight: #fff8e1;
$warningMain: #ffe57f;
$warningDark: #ffc107;

$grey50: #fafafa;
$grey100: #f5f5f5;
$grey200: #eeeeee;
$grey300: #e0e0e0;
$grey500: #9e9e9e;
$grey600: #757575;
$grey700: #616161;
$grey900: #212121;

$darkBackground: #1a223f;
$darkPaper: #111936;

$darkLevel1: #29314f;
$darkLevel2: #212946;

$darkPrimaryLight: #e3f2fd;
$darkPrimaryMain: #2196f3;
$darkPrimaryDark: #1e88e5;
$darkPrimary200: #90caf9;
$darkPrimary800: #1565c0;

$darkSecondaryLight: #d1c4e9;
$darkSecondaryMain: #7c4dff;
$darkSecondaryDark: #651fff;
$darkSecondary200: #b39ddb;
$darkSecondary800: #6200ea;

$darkTextTitle: #d7dcec;
$darkTextPrimary: #bdc8f0;
$darkTextSecondary: #8492c4;


:export {
    paper: $paper;

    primaryLight: $primaryLight;
    primary200: $primary200;
    primaryMain: $primaryMain;
    primaryDark: $primaryDark;
    primary800: $primary800;

    secondaryLight: $secondaryLight;
    secondary200: $secondary200;
    secondaryMain: $secondaryMain;
    secondaryDark: $secondaryDark;
    secondary800: $secondary800;

    successLight: $successLight;
    success200: $success200;
    successMain: $successMain;
    successDark: $successDark;

    errorLight: $errorLight;
    errorMain: $errorMain;
    errorDark: $errorDark;

    orangeLight: $orangeLight;
    orangeMain: $orangeMain;
    orangeDark: $orangeDark;

    warningLight: $warningLight;
    warningMain: $warningMain;
    warningDark: $warningDark;

    grey50: $grey50;
    grey100: $grey100;
    grey200: $grey200;
    grey300: $grey300;
    grey500: $grey500;
    grey600: $grey600;
    grey700: $grey700;
    grey900: $grey900;

    darkPaper: $darkPaper;
    darkBackground: $darkBackground;

    darkLevel1: $darkLevel1;
    darkLevel2: $darkLevel2;

    darkTextTitle: $darkTextTitle;
    darkTextPrimary: $darkTextPrimary;
    darkTextSecondary: $darkTextSecondary;

    darkPrimaryLight: $darkPrimaryLight;
    darkPrimaryMain: $darkPrimaryMain;
    darkPrimaryDark: $darkPrimaryDark;
    darkPrimary200: $darkPrimary200;
    darkPrimary800: $darkPrimary800;

    darkSecondaryLight: $darkSecondaryLight;
    darkSecondaryMain: $darkSecondaryMain;
    darkSecondaryDark: $darkSecondaryDark;
    darkSecondary200: $darkSecondary200;
    darkSecondary800: $darkSecondary800;
}
